<template>
    <b-row class="mb-3">
      <b-col cols="12">
        <div class="iq-card mb-0" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0" id="menu">
          <div id="menu-navi" class="iq-card-body d-flex align-items-center justify-content-between sm-d-table" @click="onClickNavi($event)">
            <button type="button" class="btn mr-1 btn-outline-primary" data-action="move-today">Today</button>
            <div class="d-flex sm-mt-1">
              <button type="button" class="btn btn-link iq-bg-primary" data-action="move-prev">
                <i class="fa fa-chevron-left mr-0" data-action="move-prev" />
              </button>
              <h5 id="renderRange" class="render-range mt-1 mx-4">{{ dateRange }}</h5>
              <button type="button" class="btn btn-link iq-bg-primary" data-action="move-next">
                <i class="fa fa-chevron-right mr-0" data-action="move-prev" />
              </button>
            </div>
            <div class="sm-mt-1">
              <b-form-radio-group
                id="btn-radios-1"
                v-model="selectedView"
                :options="viewModeOptions"
                buttons
                button-variant="primary"
                name="radios-btn-default"
              ></b-form-radio-group>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <calendar style="height: 800px" id="calender"
                  ref="tuiCal"
                  :useDetailPopup="useDetailPopup"
                  :view="selectedView"
                  :schedules="scheduleList"
                  :template="template"
                  :taskView="true"
                  :scheduleView="true"
                  :month="month"
                  :week="week"
                  :disableDblClick="disableDblClick"
                  :isReadOnly="isReadOnly"
                  @clickSchedule="onClickSchedule"
                  @clickDayname="onClickDayname"
                  @beforeCreateSchedule="onBeforeCreateSchedule"
                  @beforeUpdateSchedule="onBeforeUpdateSchedule"
                  @beforeDeleteSchedule="onBeforeDeleteSchedule"
        />
      </b-col>
     
    </b-row>   
</template>
<script>
import 'tui-time-picker/dist/tui-time-picker.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-calendar/dist/tui-calendar.css'
import Event from '../../../Model/Event'
import { CalenderList, Events } from '../../../FackApi/api/calendar'
import { Calendar } from '@toast-ui/vue-calendar'
import { socialvue } from '../../../config/pluginInit'
import axios from '../../../components/axios'
export default {
  name: 'App',
  components: {
    'calendar': Calendar
  },
  data () {
    return {
      viewModeOptions: [
        {
          text: 'Monthly',
          value: 'month'
        },
        {
          text: 'Weekly',
          value: 'week'
        },
        {
          text: 'Daily',
          value: 'day'
        }
      ],
      dateRange: '',
      selectedView: 'month',
      // calendarList: [],
      scheduleList: [],
      eventData:{
        'calendarId':'',
        'subject':'',
        'location':'',
        'start':'',
        'end':'',
        'isPrivate':'',
        'isAllday':false,
        'state':'',
      },
      eventDataUpdate:{
        'id':'',
        'calendarId':'',
        'subject':'',
        'location':'',
        'start':'',
        'end':'',
        'isPrivate':'',
        'isAllday':false,
        'state':'',
      },
      template: {
        milestone (schedule) {
          return `<span style="color:#3F3F3F;background-color: ${schedule.bgColor};">${schedule.title}</span>`
        },
        milestoneTitle () {
          return '<span class="tui-full-calendar-left-content">Milestone</span>'
        },
        allday (schedule) {
          return `<i class="fa fa-users"></i> ${schedule.title}`
        },
        alldayTitle () {
          return '<span class="tui-full-calendar-left-content">All Day</span>'
        }
      },
      month: {
        startDayOfWeek: 0
      },
      week: {
        showTimezoneCollapseButton: true,
        timezonesCollapsed: true
      },
      taskView: true,
      scheduleView: true,
      useDetailPopup: true,
      disableDblClick: true,
      isReadOnly: true
    }
  },
  watch: {
    selectedView (newValue) {
      this.$refs.tuiCal.invoke('changeView', newValue, true)
      this.setRenderRangeText()
    }
  },
  methods: {
    init () {
      this.setRenderRangeText()
    },
    setRenderRangeText () {
      const { invoke } = this.$refs.tuiCal
      const view = invoke('getViewName')
      const calDate = invoke('getDate')
      const rangeStart = invoke('getDateRangeStart')
      const rangeEnd = invoke('getDateRangeEnd')
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let year = calDate.getFullYear()
      let month = calDate.getMonth()
      let date = calDate.getDate()
      let dateRangeText = ''
      let endMonth, endDate, start, end
      switch (view) {
        case 'month':
          dateRangeText = `${months[month]} ${year}`
          break
        case 'week':
          year = rangeStart.getFullYear()
          month = rangeStart.getMonth() + 1
          date = rangeStart.getDate()
          endMonth = rangeEnd.getMonth() + 1
          endDate = rangeEnd.getDate()
          start = `${months[month]} ${date}`
          end = `${endDate} ${months[endMonth]}, ${year}`
          dateRangeText = `${start} - ${end}`
          break
        default:
          dateRangeText = `${date} ${months[month]} ${year}`
      }
      this.dateRange = dateRangeText
    },
    onClickNavi (event) {
      if (event.target.tagName === 'BUTTON' || event.target.tagName === 'I') {
        const { target } = event
        let action = target.dataset ? target.dataset.action : target.getAttribute('data-action')
        action = action.replace('move-', '')
        this.$refs.tuiCal.invoke(action)
        this.setRenderRangeText()
      }
    },
    onClickSchedule (res) {
      /* console.group('onClickSchedule')
      console.log('MouseEvent : ', res.event)
      console.log('Calendar Info : ', res.calendar)
      console.log('Schedule Info : ', res.schedule)
      console.groupEnd() */
    },
    onClickDayname (res) {
      // view : week, day
      /* console.group('onClickDayname')
      console.log(res.date)
      console.groupEnd() */
    },
    onBeforeUpdateSchedule (res) {
     
      var parent = this;
      parent.eventDataUpdate.id = res.schedule.id
      parent.eventDataUpdate.calendarId = res.schedule.calendarId
      parent.eventDataUpdate.subject = res.schedule.title
      parent.eventDataUpdate.location = res.schedule.location

      parent.eventDataUpdate.isPrivate = res.schedule.isPrivate
        
      parent.eventDataUpdate.start = new Date(res.schedule.start._date).toISOString();
      parent.eventDataUpdate.end = new Date(res.schedule.end._date).toISOString();
      parent.eventDataUpdate.state = res.schedule.state
      // parent.eventDataUpdate.isAllday = res.schedule.isAllday;
      if(res.isAllday){
        parent.eventDataUpdate.isAllday = true;
      }else{
        parent.eventDataUpdate.isAllday = false;
      }
      var config = {
        method: "post",
        url: `updateevent`,
        data:parent.eventDataUpdate
      }

      axios(config).then(response => {
          // parent.users = res.data
          const idx = this.scheduleList.findIndex(item => item.id === res.schedule.id)
          let updatedEvent = { ...res.schedule, ...res.changes }
          this.$set(this.scheduleList, idx, new Event(updatedEvent))
      }).catch(err => {
          console.log('status',err.response.status);
      })
      // 
    },
    onBeforeCreateSchedule (res) {
      let event = new Event(res)
      this.scheduleList.push(event)
      var parent = this;
      parent.eventData.calendarId = res.calendarId
      parent.eventData.subject = res.title
      parent.eventData.location = res.location

      if(res.raw.class === 'private'){
        parent.eventData.isPrivate = true
      }else{
        parent.eventData.isPrivate = false
      }
      
      parent.eventData.start = new Date(res.start._date).toISOString();
      parent.eventData.end = new Date(res.end._date).toISOString();
      parent.eventData.state = res.state

      if(res.isAllday){
        parent.eventData.isAllday = true;
      }else{
        parent.eventData.isAllday = false;
      }
      


      const config = {
        method: "post",
        url: "createevent",
        data:parent.eventData
      };
      axios(config).then(res => {
          parent.users = res.data
      }).catch(err => {
          console.log(err.response);
      })
      
    },
    onBeforeDeleteSchedule (res) {
      var parent = this;
      const config = {
        method: "post",
        url: "deleteevent",
        data:{
          id:res.schedule.id
        }
      };
       axios(config).then(res => {
          parent.scheduleList.splice(idx, 1)
          const idx = parent.scheduleList.findIndex(item => item.id === res.schedule.id)

        }).catch(err => {
            console.log(err.response);
        })
    },
  },
  mounted () {
    this.init()
    socialvue.index()
    // var t= new Date('Mon Mar 15 2021 11:30:00 GMT+0530 (India Standard Time)');
    // this.scheduleList = Events
    var parent = this
    const config = {
      method: "get",
      url: "calenders",
    };
    

    const config1 = {
      method: "get",
      url: "events",
    };

    axios(config1).then(res => {
        // let Event = new Event(res)
        var Ev = [];
        res.data.events.forEach(element => {
            let e;
            e = new Event(element)
            Ev.push(e)
        });
        parent.scheduleList = Ev;
    }).catch(err => {
        console.log(err.response);
    })
  }
}
</script>
