import Event from "../../Model/Event";
import moment from "moment";

export const CalenderList = [
  {
    id: "1",
    name: "My Calendar",
    bgColor: "#827af3",
    borderColor: "#827af3",
  },
  {
    id: "2",
    name: "Company",
    bgColor: "#67ddea",
    borderColor: "#67ddea",
  },
  {
    id: "3",
    name: "Family",
    bgColor: "#f35448",
    borderColor: "#f35448",
  },
  {
    id: "4",
    name: "Friend",
    bgColor: "#3dbb58",
    borderColor: "#3dbb58",
  },
  {
    id: "5",
    name: "Travel",
    bgColor: "#fbc647",
    borderColor: "#fbc647",
  },
  {
    id: "6",
    name: "Etc",
    bgColor: "#a09e9e",
    borderColor: "#a09e9e",
  },
  {
    id: "7",
    name: "Birthdays",
    bgColor: "#ffd369",
    borderColor: "#ffd369",
  },
  {
    id: "8",
    name: "National Holidays",
    color: "#fff",
    bgColor: "#fd6256",
    borderColor: "#fd6256",
  },
];

export const Events = [
  new Event({
    id: "d4902ac2-5883-5360-8af4-3345ee2d4d5d",
    calendarId: "1",
    title: "Zuz lahmi zal.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T05:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T08:30:00.000Z",
    category: "task",
    dueDateClass: "morning",
    color: "#ffffff",
    bgColor: "#827af3",
    dragBgColor: "#827af3",
    borderColor: "#827af3",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Sano zauhi buhbol igjodok ri figraipo akicaho ogramoz huwcic guisuar azpusofi fecje jeni rug.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Albert McKinney",
        avatar: "//www.gravatar.com/avatar/93c7ab8252e7d7100ce7835b0fbd3937",
        company: "Rohm & Haas Co.",
        email: "koonesuc@epigujto.kz",
        phone: "(622) 497-8105",
      },
    },
    isPrivate: false,
    location: "1235 Ozpom Plaza",
    attendees: ["Cynthia Mitchell", "Susan Brooks"],
  }),
  new Event({
    id: "71e1014b-cd69-5ea8-992d-3268a66445a0",
    calendarId: "1",
    title: "Urovu rehvuw mevzirvu.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T13:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T16:30:00.000Z",
    category: "task",
    dueDateClass: "morning",
    color: "#ffffff",
    bgColor: "#827af3",
    dragBgColor: "#827af3",
    borderColor: "#827af3",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Free",
    raw: {
      memo:
        "Wuj dav kirvivda jivbemik sos recmeec petis mitusas maro gudu wive rafohruc lehes colto loval hajfu atiedudu cubme.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Johanna Rose",
        avatar: "//www.gravatar.com/avatar/6217d6866512819f1295dba8b60be54c",
        company: "Dover Corporation, Inc.",
        email: "lo@iwrig.sd",
        phone: "(800) 365-1217",
      },
    },
    isPrivate: false,
    location: "1865 Jazbo Highway",
    attendees: [
      "Lula Anderson",
      "Jane Lawson",
      "Rose Ingram",
      "Polly Briggs",
      "Cecelia Stewart",
      "Dale Glover",
      "Estella Owens",
      "Lora Soto",
      "Jared Andrews",
    ],
  }),
  new Event(
    {
      id: "04ddefe2-db25-55e7-8293-33e6bae02c7e",
      calendarId: "1",
      title: "Ojulovfe numo kam.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T19:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T22:00:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#827af3",
      dragBgColor: "#827af3",
      borderColor: "#827af3",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 18,
      comingDuration: 18,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Canpo otfew pug pidufdew ibweok vegculih fatijpa as ud tihhum ziho ab dim ki silre hin kiipe.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Gussie Horton",
          avatar: "//www.gravatar.com/avatar/b0a18587172552ad0bae33ce3392b994",
          company: "AdvancePCS, Inc.",
          email: "kamizwo@awwi.uz",
          phone: "(575) 355-3782",
        },
      },
      isPrivate: false,
      location: "1811 Jopsif Avenue",
      attendees: [],
    },
    {
      id: "cae81b0b-8a12-5116-862f-296935be296a",
      calendarId: "1",
      title: "Ka afifopfik ri.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T06:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T09:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#827af3",
      dragBgColor: "#827af3",
      borderColor: "#827af3",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Bugzuklin una tojeroud ejretuh omihewi rizrig huwaka res daw mahofen kafcuvif tebvefo gamjeznef.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Louise Hawkins",
          avatar: "//www.gravatar.com/avatar/48f06f6c93d059fe7f08d0c7b1003d22",
          company: "Group 1 Automotive Inc.",
          email: "fezsagul@tofme.cx",
          phone: "(650) 758-4505",
        },
      },
      isPrivate: false,
      location: "1345 Nafne Path",
      attendees: ["Earl Patton"],
    }
  ),
  new Event(
    {
      id: "632890f6-3b8d-5a2b-abf8-fb19c7fe1a61",
      calendarId: "1",
      title: "Lozlin haboheudo ca.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T12:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T12:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#827af3",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 105,
      comingDuration: 49,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Luz cade nosa zobnez suje cuborci fono la ojna suv setpozeza ku ote.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Adam Flowers",
          avatar: "//www.gravatar.com/avatar/75fcaa2315f578632df55f839cec1269",
          company: "MetLife Inc.",
          email: "otetadu@rugbu.cw",
          phone: "(379) 569-4161",
        },
      },
      isPrivate: false,
      location: "948 Nenif Ridge",
      attendees: [],
    },
    {
      id: "1294d631-fb00-5e58-80b7-f6516c4c531d",
      calendarId: "1",
      title: "Buwjesda eluhul wilec.",
      body: "Poho izo hiv pivholo jeftis ticpud porhi lep al zep.",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T11:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T14:30:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#827af3",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Gobbijoc kiude focvi sejgim ji pesdawlud ji vi noj huwso hujiro sivopapi hekpe vuglo.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Warren Nguyen",
          avatar: "//www.gravatar.com/avatar/2dfc6929c9211c8059ac3d141c9c7315",
          company: "BJ Services Company",
          email: "fitcok@kukbisen.lr",
          phone: "(908) 778-2108",
        },
      },
      isPrivate: false,
      location: "688 Punfet Lane",
      attendees: [],
    },
    {
      id: "ff3067d9-a7d7-5c3b-9299-9fe3a76a2903",
      calendarId: "1",
      title: "Rocoszob fa zafkop.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T19:00:00.000Z",
      end: "2020-02-02T23:00:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#827af3",
      dragBgColor: "#827af3",
      borderColor: "#827af3",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 56,
      comingDuration: 56,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Dippos ju vika ho laret pobmok pegic ek fe vo eg suj gitazav konrif rob.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Elmer Warren",
          avatar: "//www.gravatar.com/avatar/d3a848c11e6703c7eea8cc4560e95f37",
          company: "General Cable Corporation",
          email: "codere@vos.mc",
          phone: "(989) 830-6108",
        },
      },
      isPrivate: false,
      location: "1782 Sone Path",
      attendees: [
        "Brett Underwood",
        "Leon Moody",
        "Elijah Payne",
        "Mayme Fleming",
        "Susan Waters",
        "Connor Barrett",
        "Bertie Romero",
      ],
    }
  ),
  new Event(
    {
      id: "a938ed34-ac37-50b3-9722-f09337759c2d",
      calendarId: "1",
      title: "Maz huse tawit.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T06:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T08:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#827af3",
      dragBgColor: "#827af3",
      borderColor: "#827af3",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Ube ag beteji baggizvu evtuj higmubpi ni pumi toitifi hapum de vuc.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Louise Carroll",
          avatar: "//www.gravatar.com/avatar/d2ccf030afe5c645277caa980fa59d91",
          company: "Audiovox Corporation",
          email: "ide@moga.gr",
          phone: "(704) 568-2436",
        },
      },
      isPrivate: true,
      location: "589 Wiwpag Trail",
      attendees: [],
    },
    {
      id: "4bab7e0b-6d82-50ab-a1f6-2980b867bed6",
      calendarId: "1",
      title: "Jir enihul detsinok.",
      body: "Otnocib ca ce wilni zonaroma rihi ez vilteeja ireja kimzuw.",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T00:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T04:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#827af3",
      dragBgColor: "#827af3",
      borderColor: "#827af3",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Nucoknu kimav wicmafo zol laca ehovjug kubuw fu inezaz ohotijle cedbe few bizanif bibefi ibemabjiz aw kerurihap.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Ricardo Dennis",
          avatar: "//www.gravatar.com/avatar/f59d7e49a0be9c9a6ef739411c73a938",
          company: "International Business Machines Corp.",
          email: "tej@rum.ba",
          phone: "(726) 941-6396",
        },
      },
      isPrivate: false,
      location: "1031 Fovig View",
      attendees: [],
    },
    {
      id: "7090c752-7612-5ae4-a6df-378e8bd2affc",
      calendarId: "1",
      title: "Runcu wec gofdonen.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T23:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T00:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#827af3",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 89,
      comingDuration: 66,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Tesgok liceka wuew jo vidav apvuzse rabmaino lemcalto mal jeguwif dec ejugofen fibih.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Francis Berry",
          avatar: "//www.gravatar.com/avatar/845be6127a7bb7392d4e45946e27fbfd",
          company: "Exide Technologies",
          email: "wa@joki.mv",
          phone: "(629) 208-3504",
        },
      },
      isPrivate: false,
      location: "902 Viraj Mill",
      attendees: [],
    },
    {
      id: "20244287-08e7-584c-9202-b86ebe6154a3",
      calendarId: "2",
      title: "Pih korbak zujta.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T23:30:00.000Z",
      end: "2020-02-02T03:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#67ddea",
      dragBgColor: "#67ddea",
      borderColor: "#67ddea",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Faborukuf huwe tiwoj awur heide bevnudles budlohat huhawwif tulzibgob gut jejawmoh giv dah hugcijle cemopisuk womlapano kovniv orid.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Ella Townsend",
          avatar: "//www.gravatar.com/avatar/7c7215f5cd0e3d2b3dd6e3a6789e9713",
          company: "Pioneer-Standard Electronics Inc.",
          email: "avwimro@doara.mk",
          phone: "(258) 247-3644",
        },
      },
      isPrivate: false,
      location: "368 Kato Highway",
      attendees: [],
    },
    {
      id: "aa449646-72c4-53b9-95c1-665c7eae3cba",
      calendarId: "2",
      title: "Fecbi vok turoh.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T11:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T15:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#67ddea",
      dragBgColor: "#67ddea",
      borderColor: "#67ddea",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Wivmudel jegef gepbikgof nitsucna vaf ba bohinaci sewga turazi tinnom uzuma jobvic ogudades but dahnot.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Matilda Townsend",
          avatar: "//www.gravatar.com/avatar/d0e4de1b79445ba277b82a5fc7a69eea",
          company: "Frontier Oil Corp",
          email: "hazjo@pag.sc",
          phone: "(538) 487-7888",
        },
      },
      isPrivate: false,
      location: "945 Bavrir Plaza",
      attendees: [
        "Josephine Martinez",
        "Marian Saunders",
        "Mina Stevenson",
        "Cecilia Clayton",
        "Susan Soto",
        "Theodore Montgomery",
        "Dale Simmons",
        "Eugene Schneider",
      ],
    }
  ),
  new Event({
    id: "d08c31f9-d03a-5f58-b3a9-877b903102d3",
    calendarId: "2",
    title: "Ig rijut edi.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T16:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T20:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#67ddea",
    dragBgColor: "#67ddea",
    borderColor: "#67ddea",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Wuwkagimu ipu popnaro wosje gafhu poikac buguzoez adice waotuhi junnabcu ka hawzic killu fi caaz ka upi jifi.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Minnie Hale",
        avatar: "//www.gravatar.com/avatar/3a5b9ab7559cba3511abbbd2994286c1",
        company: "Enron Corp.",
        email: "pigmajobe@fimlelmij.re",
        phone: "(384) 484-8291",
      },
    },
    isPrivate: false,
    location: "1071 Fodem Highway",
    attendees: [
      "Nettie Fleming",
      "Blake Rios",
      "Lee Greene",
      "Laura Rodriguez",
      "Hulda Zimmerman",
      "Lulu Newman",
      "Millie Malone",
      "Frank Sherman",
      "Elmer Doyle",
      "Myrtie Christensen",
    ],
  }),
  new Event(
    {
      id: "ff051b03-0222-5acb-a246-4993c9662a19",
      calendarId: "2",
      title: "Civjaob vudpaofu ka.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T12:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T15:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#67ddea",
      dragBgColor: "#67ddea",
      borderColor: "#67ddea",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Apcu wuativif unulavfi uztowim hauzzim faki aw ju onnupah murnalak vib upavbav dehadne dezfa da hega bimkib fajsic.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Pearl Valdez",
          avatar: "//www.gravatar.com/avatar/32c3dfb49f8ba82c41db42958fdcdef8",
          company: "ABM Industries Incorporated",
          email: "bonbu@zambo.net",
          phone: "(876) 412-8538",
        },
      },
      isPrivate: false,
      location: "1200 Zunsum Pass",
      attendees: [],
    },
    {
      id: "7e2a644c-50fb-5343-a70e-5df4c8f64dfa",
      calendarId: "2",
      title: "Keuvke jerlo sazego.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T18:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T22:30:00.000Z",
      category: "task",
      dueDateClass: "morning",
      color: "#ffffff",
      bgColor: "#67ddea",
      dragBgColor: "#67ddea",
      borderColor: "#67ddea",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "He cev cefew ijogiw pazejo sa ti ni behoc buk sigag efeted dak log.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Kevin Vargas",
          avatar: "//www.gravatar.com/avatar/2c5577db6f2d523cee19173dd7d7c8da",
          company: "Reader's Digest Association Inc.",
          email: "hejbuk@arbahdad.au",
          phone: "(564) 948-5061",
        },
      },
      isPrivate: false,
      location: "1561 Firjak Manor",
      attendees: [
        "Daisy Jimenez",
        "Lily Barnett",
        "Myra Walters",
        "Ruth Foster",
        "Samuel Goodman",
        "Florence Soto",
        "Dominic Tyler",
        "Violet Allison",
        "Josie Beck",
        "Jordan Perry",
      ],
    }
  ),
  new Event(
    {
      id: "f620d5e2-1df0-56c1-a0b1-bfae8117cd83",
      calendarId: "2",
      title: "Rom lubcag sijrun.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T20:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T00:30:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#67ddea",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 22,
      comingDuration: 22,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Jonem vodizfo ozegukeb medvek riv ureputna uj uznakiw nirtumgev jamol topciv lirkofin wo tajli defu zuvimesi iwaame pivom.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Richard Harrington",
          avatar: "//www.gravatar.com/avatar/d6494d882e85ee0c2321e01ea8b072f6",
          company: "Humana Inc.",
          email: "honavsi@run.ps",
          phone: "(763) 856-5081",
        },
      },
      isPrivate: false,
      location: "1900 Weucu Loop",
      attendees: [],
    },
    {
      id: "42fd1b59-60bd-5765-a2cc-4e07e81ec3c9",
      calendarId: "2",
      title: "Nucuni kobi lebu.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T02:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T05:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#67ddea",
      dragBgColor: "#67ddea",
      borderColor: "#67ddea",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Hun asita cocuwob neez ras dutipgo uvu veiw bewu himvohise fultitze toehacos badep.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Andre Snyder",
          avatar: "//www.gravatar.com/avatar/058418c3a30b736cb075505880a57c3e",
          company: "Comfort Systems USA Inc.",
          email: "niw@ajwelu.pk",
          phone: "(818) 389-5159",
        },
      },
      isPrivate: false,
      location: "1515 Voroc View",
      attendees: [
        "Olive Blake",
        "Marcus McCarthy",
        "Laura Gilbert",
        "Harold Brooks",
        "Mae McCoy",
        "Glen Marshall",
        "Stephen Matthews",
        "Carolyn Newman",
        "Joshua Casey",
        "Zachary Colon",
      ],
    }
  ),
  new Event({
    id: "52a0f7c8-0164-5b00-bdf4-3c580974df7c",
    calendarId: "2",
    title: "Uhcekme ammukve cinez.",
    body: "Cud vasebucuv cabaaho vuvhid dojta vizem pipissur cub dado fa.",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T08:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T10:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#67ddea",
    dragBgColor: "#67ddea",
    borderColor: "#67ddea",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Pujisoto honasin me lujil wopok sogeic miz siwususa nab vizwu cin kehof ise.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Beulah Thomas",
        avatar: "//www.gravatar.com/avatar/8cc206482ab46967f843bd569201abf5",
        company: "Southwest Gas Corp",
        email: "tadig@we.gr",
        phone: "(830) 373-6828",
      },
    },
    isPrivate: false,
    location: "1464 Tawu Pass",
    attendees: [
      "John Ramsey",
      "Gary Davidson",
      "Clayton Ruiz",
      "Oscar Brewer",
      "Glenn Jimenez",
      "Henry Austin",
    ],
  }),
  new Event({
    id: "ae4ff955-b446-5187-8f58-9cffaf8a30b3",
    calendarId: "2",
    title: "Binereke dobaci ejkev.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T04:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T08:00:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#67ddea",
    dragBgColor: "#67ddea",
    borderColor: "#67ddea",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Cuwaima hujarze un enhim neuvejup ned ufkokpe mapeliz ah so denu lutgu visem edejuw femo bepcu sukrejwu.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Annie Dawson",
        avatar: "//www.gravatar.com/avatar/caa7ea1a6bb7b30273bc0400d6ea3e02",
        company: "Ryerson Tull Inc",
        email: "sah@igtezeg.vu",
        phone: "(600) 446-1141",
      },
    },
    isPrivate: false,
    location: "1601 Camni Court",
    attendees: [
      "Florence Stewart",
      "Dorothy Ingram",
      "Clara Stephens",
      "Callie Santiago",
      "Derrick Waters",
      "Jimmy Long",
    ],
  }),
  new Event({
    id: "3fb30201-caad-550e-936c-f2b2e88cda7c",
    calendarId: "2",
    title: "Ebsu vodpok pukezoho.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T14:30:00.000Z",
    end: "2020-02-02T16:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#67ddea",
    dragBgColor: "#67ddea",
    borderColor: "#67ddea",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Wosvifwe aj mufsamkip wutevpe ipicug kewlo vajba deemubi tokce afoputi tis uda.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Gene McCoy",
        avatar: "//www.gravatar.com/avatar/5c2bb4ab824c55da6c3a22061f7e1bb4",
        company: "EMC Corporation",
        email: "geb@ibohizhi.sj",
        phone: "(282) 735-3364",
      },
    },
    isPrivate: false,
    location: "1522 Valhot Pass",
    attendees: ["Mina Williamson", "Aaron Reyes"],
  }),
  new Event({
    id: "5752d30e-ffa6-585c-a78b-394f36792a41",
    calendarId: "3",
    title: "Ref dawoj pi.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T09:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T13:00:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#f35448",
    dragBgColor: "#f35448",
    borderColor: "#f35448",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Free",
    raw: {
      memo:
        "Fu ruliducu aduku pa kot kovek gah fepabiba nurmec puh wuwzalem nuwi fodwiut umear dotavec tarez.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Michael Bryan",
        avatar: "//www.gravatar.com/avatar/a4f61cfd9d53af54b8ab4cb3d7788089",
        company: "Health Management Associates Inc.",
        email: "zape@cozas.lt",
        phone: "(283) 295-7169",
      },
    },
    isPrivate: false,
    location: "1718 Ubzit Pass",
    attendees: ["James Kelly"],
  }),
  new Event({
    id: "ec8dc1bb-4e72-5c24-b86a-092f70ba2c66",
    calendarId: "3",
    title: "Nurum uduuvi mupkam.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T14:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T15:00:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#f35448",
    dragBgColor: "#f35448",
    borderColor: "#f35448",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 49,
    comingDuration: 49,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Caniud ap niinga fo fi ce fahami cod pi madkoh esejarsi zudte zewzama zezmu deuju meep us muglog.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Derrick Evans",
        avatar: "//www.gravatar.com/avatar/0be1be8c2a1e5e3539ddc135d5f1a188",
        company: "Snap-On Inc",
        email: "mortoap@nuhzi.mm",
        phone: "(410) 705-7002",
      },
    },
    isPrivate: false,
    location: "1238 Acewa Ridge",
    attendees: [
      "Francisco Long",
      "Luis Gardner",
      "Norman Rose",
      "Lilly Black",
      "Ola Ramirez",
      "Garrett Henry",
      "Louis Curtis",
    ],
  }),
  new Event(
    {
      id: "3578ee37-a123-5482-8951-3802343c68c0",
      calendarId: "3",
      title: "Olehe ve war.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T05:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T07:00:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#f35448",
      dragBgColor: "#f35448",
      borderColor: "#f35448",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Jafpuproh jeksevnon bajjogdat uhnohac tocarzew fuciljek ucencem hosizup notjihip mi noghu uv no no onimuhi ub.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Winnie Oliver",
          avatar: "//www.gravatar.com/avatar/f7d3b45a36dbe5d8e188c0e4a004e4ee",
          company: "TECO Energy Inc",
          email: "so@hetbaro.ee",
          phone: "(950) 795-6028",
        },
      },
      isPrivate: false,
      location: "1502 Sasunu Place",
      attendees: [],
    },
    {
      id: "e6f63ca5-2ea9-5dc0-9a5a-b24d3a810f90",
      calendarId: "3",
      title: "Fon il bukvungi.",
      body: "Wo olmaczan pizlew binegmof aknaf up ojecaf waren cine ilamu.",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T00:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T04:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#f35448",
      dragBgColor: "#f35448",
      borderColor: "#f35448",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Felafka rujrul fin suru vad zurej makdujoz befde buigu eluwibif pime gukizjit numsek goh ugapuw mi.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Marie Rivera",
          avatar: "//www.gravatar.com/avatar/b5a297e56fb7406acee9955831f0e372",
          company: "J.B. Hunt Transport Services Inc.",
          email: "iwoj@al.au",
          phone: "(336) 417-5849",
        },
      },
      isPrivate: false,
      location: "1209 Azzim Manor",
      attendees: [
        "Carl Cruz",
        "Jon Bennett",
        "Floyd Herrera",
        "Dora Fleming",
        "Nettie McGee",
        "Roxie Sutton",
        "Blanche Porter",
      ],
    }
  ),
  new Event({
    id: "94cdd948-0641-5522-95f5-69f523591fb6",
    calendarId: "3",
    title: "Mimi mag gejo.",
    body: "Hiz bor juctetaho onuasji mis ace iseufa me cisat lizta.",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T06:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T10:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#f35448",
    dragBgColor: "#f35448",
    borderColor: "#f35448",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Rewop vo lazoejo zozevagok isopod medejvo huj re ca suzucuji la gopkagce ta hizomu satomah.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Josephine Norton",
        avatar: "//www.gravatar.com/avatar/5066f12afd3b532fbf8744fa5f7602a0",
        company: "Dominion Resources, Inc.",
        email: "golum@felpowib.net",
        phone: "(276) 916-3864",
      },
    },
    isPrivate: true,
    location: "1589 Geblen Trail",
    attendees: [
      "Leon Freeman",
      "Barry Henry",
      "Mamie White",
      "Larry Maxwell",
      "Corey Curtis",
      "Elizabeth Walton",
    ],
  }),
  new Event({
    id: "f4254295-407b-5544-be4f-613b2384d764",
    calendarId: "3",
    title: "Umiunoibu vomouju eteni.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T09:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T13:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#f35448",
    dragBgColor: "#f35448",
    borderColor: "#f35448",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Zocafnew sivenno na babmim raobine okwufnu ato ti cu wof degsec fivune zik muge ohejin wo.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Jerome Mitchell",
        avatar: "//www.gravatar.com/avatar/48e92fb321680f8d474db10ae083d993",
        company: "Liz Claiborne Inc.",
        email: "fispe@veufaot.bv",
        phone: "(667) 823-3355",
      },
    },
    isPrivate: false,
    location: "1052 Rapho Plaza",
    attendees: [
      "Leon Shelton",
      "Dean Perkins",
      "Raymond Schultz",
      "Susan Thornton",
      "Myrtle Harper",
      "Timothy Phelps",
      "Roxie Jimenez",
      "Marian Harrison",
      "Bryan Carpenter",
      "Susan Perez",
    ],
  }),
  new Event({
    id: "3805220e-22af-5c44-9eac-aff4ef694de8",
    calendarId: "3",
    title: "Dinin ciav mer.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T05:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T07:00:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#f35448",
    dragBgColor: "#f35448",
    borderColor: "#f35448",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Ced seh ic ru gaiz ravfakpi rutvimju mu ukelasov jan vu ogo pec mu fuc ba.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Inez Sandoval",
        avatar: "//www.gravatar.com/avatar/6a717eb8cb5501098c2a417da7c6c76d",
        company: "Applera Corporation",
        email: "ocrilef@if.tz",
        phone: "(639) 351-7523",
      },
    },
    isPrivate: false,
    location: "1335 Kikuv Path",
    attendees: [
      "Sophia Hale",
      "Alan Thompson",
      "Fannie Pittman",
      "Verna Obrien",
      "Pearl Sherman",
      "Rosalie Hart",
    ],
  }),
  new Event({
    id: "4ee711ca-df8c-5573-8a9b-798fc186261e",
    calendarId: "3",
    title: "Hizci busos fomkup.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T20:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T22:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#f35448",
    dragBgColor: "#f35448",
    borderColor: "#f35448",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Zahvaet be cuppammov adoridu cibi iwluwma bis ole pofuz suzdorib fagecpud pose fabnam kiw he roenni.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Clara Ward",
        avatar: "//www.gravatar.com/avatar/d6db823d289262c2b604e326b3ff88b7",
        company: "Zale Corporation",
        email: "mimalo@lenevar.is",
        phone: "(427) 376-2979",
      },
    },
    isPrivate: false,
    location: "13 Togsa Terrace",
    attendees: ["Zachary Moss", "Mike Hawkins", "Douglas Schultz"],
  }),
  new Event(
    {
      id: "cc668598-f955-5f69-92e5-74b933600712",
      calendarId: "3",
      title: "Okavul daslos visinuka.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T09:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T13:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#f35448",
      dragBgColor: "#f35448",
      borderColor: "#f35448",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Rol hobmo bifdas popori po gehafeki viliire se ho cuad leza ceilpi sotilebes fik oziito rogzikfa.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Cory Bradley",
          avatar: "//www.gravatar.com/avatar/01377b0f1e621b57f6a99fa396751a15",
          company: "Owens Corning",
          email: "ras@zoz.sv",
          phone: "(461) 618-9099",
        },
      },
      isPrivate: false,
      location: "731 Teti Drive",
      attendees: [],
    },
    {
      id: "71fb8305-d96b-52b5-bfc2-9aba03f8ce3e",
      calendarId: "3",
      title: "Gatad wu na.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T07:00:00.000Z",
      end: "2020-02-03T08:00:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#f35448",
      dragBgColor: "#f35448",
      borderColor: "#f35448",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 16,
      comingDuration: 16,
      recurrenceRule: "repeated events",
      state: "Free",
      raw: {
        memo:
          "Jijdemgo uznuw muojohe hecci ub wes mupe coad gip evuide vulofgan vic tev it zo.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Antonio Cruz",
          avatar: "//www.gravatar.com/avatar/8845d02b6a9c6a5f916553704b5b06f3",
          company: "Prudential Financial Inc.",
          email: "derar@kosonvan.iq",
          phone: "(551) 598-2007",
        },
      },
      isPrivate: false,
      location: "989 Abahas Street",
      attendees: [
        "Billy Porter",
        "Hester Chapman",
        "Tony Freeman",
        "Mabel Alvarez",
        "Mayme Henderson",
        "Georgia Haynes",
        "Rachel Campbell",
      ],
    }
  ),
  new Event({
    id: "1240b3b7-f876-5859-93c8-419ebb11b96e",
    calendarId: "4",
    title: "Bome su vansunla.",
    body: "Ga rif nona seb co tujgit zope ev decur uco.",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T13:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T16:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#3dbb58",
    dragBgColor: "#3dbb58",
    borderColor: "#3dbb58",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Huki meb magle zi wauzalo fiwogif soropoho ma ozudiv ahovad tor zowiz pinat.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Mildred Grant",
        avatar: "//www.gravatar.com/avatar/7ee29bb7ff86a7c9ca3996f0821381a0",
        company: "DQE Inc.",
        email: "tunroun@bosud.mo",
        phone: "(736) 812-1000",
      },
    },
    isPrivate: false,
    location: "49 Awarak Manor",
    attendees: [
      "Maurice Welch",
      "Mildred Cross",
      "Lydia Mills",
      "Isabelle Simpson",
      "Callie Soto",
      "Jeffrey McDaniel",
      "Della Knight",
      "Bernice Brewer",
    ],
  }),
  new Event({
    id: "342a65b4-fab1-5f37-a52e-b5ebc917553f",
    calendarId: "4",
    title: "Vukil duvinbu bemunun.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T14:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T17:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#3dbb58",
    dragBgColor: "#3dbb58",
    borderColor: "#3dbb58",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 69,
    comingDuration: 57,
    recurrenceRule: "",
    state: "Free",
    raw: {
      memo:
        "Pin imenaroz gib viphejvo vafinzeg nokrot anje lu coj hup duwig um tofavbo finiiti.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Celia Hart",
        avatar: "//www.gravatar.com/avatar/97d09a15c227afce5ebe21b28787836b",
        company: "National City Corp.",
        email: "til@ar.bd",
        phone: "(548) 251-8690",
      },
    },
    isPrivate: false,
    location: "40 Keebi Ridge",
    attendees: [
      "Tony McKinney",
      "Devin Marsh",
      "Luella McLaughlin",
      "Rosie Burns",
      "Raymond Rogers",
      "Lottie Atkins",
    ],
  }),
  new Event(
    {
      id: "342f2a41-7e62-5f23-99d3-ac48b69d7696",
      calendarId: "4",
      title: "Ge zozibaz je.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T14:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T16:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#3dbb58",
      dragBgColor: "#3dbb58",
      borderColor: "#3dbb58",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Jite uduceneh egunup hu ifuc tig sirapogud piwoju luomna gosnaw hasba zijdafu boati ri lisfom upofser he.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Jeffrey Hansen",
          avatar: "//www.gravatar.com/avatar/7fe56f6d5e85b6e050d2ecc45fa409da",
          company: "Ecolab Inc.",
          email: "ifboh@zuuk.co.uk",
          phone: "(274) 362-5423",
        },
      },
      isPrivate: false,
      location: "1311 Kewnuh Loop",
      attendees: [],
    },
    {
      id: "4e398385-beff-5342-adb8-ed210a26af7c",
      calendarId: "4",
      title: "Jivalu puh kug.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T16:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T16:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#3dbb58",
      dragBgColor: "#3dbb58",
      borderColor: "#3dbb58",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 63,
      comingDuration: 47,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Canos ke ikize ekne jiwemov vebtibzog ep pil hubli ravan bigbofi sowapi wupiz sobodbe zoh pif.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Charles Padilla",
          avatar: "//www.gravatar.com/avatar/7fe253803666d4aab09c0bce8b83f846",
          company: "Hershey Foods Corp.",
          email: "iwuwa@wuka.us",
          phone: "(262) 479-9646",
        },
      },
      isPrivate: false,
      location: "253 Sume Park",
      attendees: ["Martha Vaughn"],
    }
  ),
  new Event({
    id: "c7d14bfd-7bdc-584e-b17c-587467b1363d",
    calendarId: "4",
    title: "Jel gempe ercan.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T03:00:00.000Z",
    end: "2020-02-03T04:00:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#3dbb58",
    dragBgColor: "#3dbb58",
    borderColor: "#3dbb58",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Rineto etiolkal hajjev nuraca rohfow hecom docego hid kibijweg rej kamfinulo vemito id effok fajci.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Sean Collier",
        avatar: "//www.gravatar.com/avatar/dbd142c1fde2e99ea704f6a4afca5bdc",
        company: "Del Monte Foods Co",
        email: "evuugedo@odaebuiha.rw",
        phone: "(525) 348-3938",
      },
    },
    isPrivate: false,
    location: "1023 Wace Drive",
    attendees: [
      "Miguel Potter",
      "Leo Mann",
      "Lee Carr",
      "Olivia Ryan",
      "Micheal Beck",
      "Bill Dennis",
    ],
  }),
  new Event(
    {
      id: "a3aca8f0-b316-5cd5-8605-30086a50a3d7",
      calendarId: "4",
      title: "Kegjorip vejmijhi leer.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T16:30:00.000Z",
      end: "2020-02-02T18:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#3dbb58",
      dragBgColor: "#3dbb58",
      borderColor: "#3dbb58",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Bom apawe kelipfef haraso doceb sizev peh raw akusud zo dehaga jog luzzelmu udi fogareac assijruk ucze jat.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Maggie Burgess",
          avatar: "//www.gravatar.com/avatar/fa406d9ac290df15d40b3ea799350a05",
          company: "Arkansas Best Corporation",
          email: "kufdizo@ke.au",
          phone: "(926) 786-7539",
        },
      },
      isPrivate: false,
      location: "403 Awwok Mill",
      attendees: [],
    },
    {
      id: "a4bb51f6-4c0b-54a4-97e9-6154c8af2d66",
      calendarId: "4",
      title: "Zapmogic meeg meege.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T20:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T22:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#3dbb58",
      dragBgColor: "#3dbb58",
      borderColor: "#3dbb58",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Luum omipaj god hij jer heb dezale visdu corvag fibide kam woc el hajwudmoj ew resi bet adifah.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Lucille Blair",
          avatar: "//www.gravatar.com/avatar/b2c4dc9974dc193f4e6e4a78bc9f3c79",
          company: "National Commerce Financial Corporation",
          email: "gifumni@ora.bd",
          phone: "(952) 805-8425",
        },
      },
      isPrivate: false,
      location: "247 Zufri Highway",
      attendees: [
        "Annie Jimenez",
        "Christine Nash",
        "Lettie Marsh",
        "Milton Beck",
        "Luke Washington",
        "Todd Ramirez",
        "Mildred Hawkins",
      ],
    }
  ),
  new Event({
    id: "788b2052-edeb-54be-afb5-33e50dcaf612",
    calendarId: "4",
    title: "Zicehil maar guvsi.",
    body: "Degwu zi velo bo fahiem rigvi nip pezwep ciuwuh ere.",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T00:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T04:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#3dbb58",
    dragBgColor: "#3dbb58",
    borderColor: "#3dbb58",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "La hi wunci jintihew necma vigozi vamcuw bu fapwaja bujiivu jumo zo gigcura rotofewu dik duzip di wete.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Mabelle Welch",
        avatar: "//www.gravatar.com/avatar/83dbde0e26481d0be16ed347fbe25624",
        company: "Gentek Inc.",
        email: "uzepo@jinsagnec.fr",
        phone: "(488) 437-6087",
      },
    },
    isPrivate: false,
    location: "1580 Cona Court",
    attendees: ["May Carroll"],
  }),
  new Event({
    id: "4d8835e1-ebe9-5137-83ba-17ca1c9eb10f",
    calendarId: "4",
    title: "Jammihfeh ojcot jum.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T12:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T14:30:00.000Z",
    category: "milestone",
    dueDateClass: "",
    color: "#3dbb58",
    bgColor: "transparent",
    dragBgColor: "transparent",
    borderColor: "transparent",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Uzo bonheiho ijosakgat miekfig dabcotuwu nawji wambeje te vekaoho rej jojat vudput femcasnif pitokepa ca ze.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Susie Gibbs",
        avatar: "//www.gravatar.com/avatar/f9d4817f4c375f10079861a74f7a26be",
        company: "Marsh & McLennan Companies Inc.",
        email: "notum@wo.ug",
        phone: "(860) 553-7872",
      },
    },
    isPrivate: false,
    location: "1207 Avnac Plaza",
    attendees: [
      "Frances Larson",
      "Ronnie Wise",
      "Kenneth Murphy",
      "Madge Patton",
      "Justin Reed",
    ],
  }),
  new Event(
    {
      id: "18ba12a0-b61d-58d7-ac00-63f8a1578093",
      calendarId: "4",
      title: "Hadpuk unutalov hakatol.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T03:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T04:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#3dbb58",
      dragBgColor: "#3dbb58",
      borderColor: "#3dbb58",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 24,
      comingDuration: 24,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Pil miptakniv amapeag rih nikta niib de widovip kibikvo eba orata huvuckav pokob kat iluzag ekoviwvap.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Josie Briggs",
          avatar: "//www.gravatar.com/avatar/ffadcd7083f34d6d2c34e8af724b4e0a",
          company: "Alleghany Corporation",
          email: "wujmebni@zikihar.uy",
          phone: "(409) 758-9574",
        },
      },
      isPrivate: false,
      location: "1248 Hunvo Key",
      attendees: [],
    },
    {
      id: "93b3a527-67d1-5236-acf0-b98578be7711",
      calendarId: "5",
      title: "Sifze hoh idoka.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T08:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T11:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fbc647",
      dragBgColor: "#fbc647",
      borderColor: "#fbc647",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Koco he te ke hoksic kezbakagu ro vov himwaffaj kivovof odopsa cejev mowpave zoz biwi diphu mavijem fet.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Dorothy Harrington",
          avatar: "//www.gravatar.com/avatar/c0973b19e0b13eb4dfe78192cbf3d9ef",
          company: "Leggett & Platt Inc.",
          email: "acu@ufoju.pn",
          phone: "(630) 649-8252",
        },
      },
      isPrivate: false,
      location: "1128 Wulen Avenue",
      attendees: [],
    },
    {
      id: "523f25bb-d198-582c-8cc0-23a7dcd6695d",
      calendarId: "5",
      title: "Vozuki vo ham.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T04:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T05:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fbc647",
      dragBgColor: "#fbc647",
      borderColor: "#fbc647",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Gami wutiragu coerahom bekokla wiscuv cu vezi jotnoemi hog kaj petikfu timarhak woppu kicujiog dukem.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Michael Griffith",
          avatar: "//www.gravatar.com/avatar/6e118aee83a8208c4e4c287070397944",
          company: "Hub Group Inc.",
          email: "zihhofug@cufvuz.tc",
          phone: "(588) 321-7383",
        },
      },
      isPrivate: false,
      location: "554 Roga Turnpike",
      attendees: [
        "Lizzie Willis",
        "Nell Simmons",
        "Blake Waters",
        "Daniel Todd",
        "Charlotte Sandoval",
        "Mary McCormick",
        "Lilly Holland",
        "Helena Bass",
        "Myrtle Chapman",
        "Derek Reeves",
      ],
    }
  ),
  new Event({
    id: "5bb3f774-1188-5394-a2d9-88b307d71ad2",
    calendarId: "5",
    title: "Wigja kopil lihzeb.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T18:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T21:30:00.000Z",
    category: "task",
    dueDateClass: "morning",
    color: "#ffffff",
    bgColor: "#fbc647",
    dragBgColor: "#fbc647",
    borderColor: "#fbc647",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Free",
    raw: {
      memo:
        "Zojura pus mibzulwo lusfaw hojepula memew iheajofit zel uboco zunzakiwo moje jaefa lemuv eflosuwe dulac kafto vesopatu paguuga.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Corey Price",
        avatar: "//www.gravatar.com/avatar/03f59f91237e1291517fb1c062641914",
        company: "Scana Corp.",
        email: "zunluosa@sarol.ye",
        phone: "(935) 992-1420",
      },
    },
    isPrivate: true,
    location: "1707 Gewju Glen",
    attendees: ["Ronnie Barnett", "Olga Todd", "Harvey Crawford"],
  }),
  new Event({
    id: "2305dd14-8d66-5d3d-9cbc-e046b4ec175b",
    calendarId: "5",
    title: "Buc mavobci ocililat.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T00:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T03:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fbc647",
    dragBgColor: "#fbc647",
    borderColor: "#fbc647",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Free",
    raw: {
      memo:
        "Opjom woge hirpi zaiz urihogci duzi du vafsuv jisuv rebraf na cowferik poted.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Barry Robinson",
        avatar: "//www.gravatar.com/avatar/1bfd7a63feab6d166d18e1397275f58e",
        company: "Astoria Financial Corporation",
        email: "koje@igi.fj",
        phone: "(236) 280-4172",
      },
    },
    isPrivate: false,
    location: "1919 Miit Pass",
    attendees: ["Bobby Hart", "Cory Ramirez", "Leon Bishop"],
  }),
  new Event(
    {
      id: "387c8a1c-c1b0-5aa5-8d5c-76129a81af75",
      calendarId: "5",
      title: "Ehanol sikuhuz gaklejpit.",
      body: "Og fipullo ke usva sutev wa kefonuve coolde ware put.",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T11:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T14:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fbc647",
      dragBgColor: "#fbc647",
      borderColor: "#fbc647",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Torek nen ucaezuune rijarma kum ip ujaduv mulaviifi owowopfib tacgo ku pan nehoh tisbes.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Ola Olson",
          avatar: "//www.gravatar.com/avatar/7d1074b9d2ae766c8f1430094db359a4",
          company: "Beverly Enterprises, Inc.",
          email: "zibos@be.tz",
          phone: "(903) 358-8662",
        },
      },
      isPrivate: false,
      location: "509 Tazik Heights",
      attendees: [],
    },
    {
      id: "33f6e96e-0368-501b-9727-07831bf1a17a",
      calendarId: "5",
      title: "Veni bidnokbir ijajivjo.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T09:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T13:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fbc647",
      dragBgColor: "#fbc647",
      borderColor: "#fbc647",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Hejuzum dehavsa mabketud boli fugusbe lonocavit met pop kip no parojnas hi midre puciwhug vuveces bidez rumpiw.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Janie Hines",
          avatar: "//www.gravatar.com/avatar/0d5ce2dea488ded0b2f91b1821849c11",
          company: "Oshkosh Truck Corp",
          email: "bos@tibhogar.ug",
          phone: "(801) 304-4086",
        },
      },
      isPrivate: false,
      location: "1240 Ejeri Glen",
      attendees: ["Angel Steele", "Sue Obrien"],
    }
  ),
  new Event({
    id: "d482c00a-a4ab-5082-b5cd-9dea6203670a",
    calendarId: "5",
    title: "Wocune ewipi rivnoil.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+3, "days")
        .format("YYYY-MM-DD") + "T20:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+3, "days")
        .format("YYYY-MM-DD") + "T22:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fbc647",
    dragBgColor: "#fbc647",
    borderColor: "#fbc647",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Hertofid kubew zevocmod oc tughum botut okivasdi woferer unaduwepa gah folmozpiz azutadir logutwon hemewog.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Steve Moran",
        avatar: "//www.gravatar.com/avatar/b7f2346c7544cda7bc9fe982ac90e38c",
        company: "Solutia Inc",
        email: "pitkes@de.al",
        phone: "(417) 453-9158",
      },
    },
    isPrivate: false,
    location: "395 Defed Extension",
    attendees: [
      "Alice May",
      "Jim Howard",
      "Stanley Baldwin",
      "Fannie Porter",
      "Ivan Casey",
      "Ophelia Norton",
      "Craig Gardner",
      "Don Lopez",
      "Harriett Burns",
      "Glen Hart",
    ],
  }),
  new Event({
    id: "d50ff6f8-e802-5bfa-b59a-6c192a72f069",
    calendarId: "5",
    title: "Le tisovid redar.",
    body: "Rejun hesa tugode zocarla rotu me sadejdi bipah limuwu muves.",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T10:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T14:00:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fbc647",
    dragBgColor: "#fbc647",
    borderColor: "#fbc647",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 14,
    comingDuration: 14,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Ja gifotuw ut gawu igtu ze remtobof zo izemiv itmoje nabjout kipuvoc.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Connor Hodges",
        avatar: "//www.gravatar.com/avatar/9340aa9db3b8746bd686bbb19f0c57ff",
        company: "American Financial Group, Inc.",
        email: "dovaok@usvoan.in",
        phone: "(615) 677-7826",
      },
    },
    isPrivate: false,
    location: "1990 Muvmaf Ridge",
    attendees: ["Jack Aguilar", "Beatrice Bass", "Oscar Lloyd", "Louise Smith"],
  }),
  new Event({
    id: "59d76eb2-ff2b-55e9-ae56-bd4fce138bf5",
    calendarId: "5",
    title: "Ta ke afeekiuvo.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T22:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T23:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fbc647",
    dragBgColor: "#fbc647",
    borderColor: "#fbc647",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 52,
    comingDuration: 52,
    recurrenceRule: "",
    state: "Free",
    raw: {
      memo:
        "Izaar wencaina siz om bal nike lor rejboto evamiuko kas jar aljo cefurgut meumamow owi ci odmurno jo.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Corey Bishop",
        avatar: "//www.gravatar.com/avatar/7b851b4121935b80348e7426c7216240",
        company: "Banta Corporation",
        email: "jozgumi@di.sr",
        phone: "(628) 448-7808",
      },
    },
    isPrivate: false,
    location: "1327 Ovias Path",
    attendees: ["Kevin Aguilar", "Jean Abbott"],
  }),
  new Event(
    {
      id: "52399f6a-cf00-5653-9cbe-5ef692541123",
      calendarId: "5",
      title: "Hodimu liwib es.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T08:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T09:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fbc647",
      dragBgColor: "#fbc647",
      borderColor: "#fbc647",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Zim fummam ade japin denpeti dofum amod vorva wujizha utebeefa wumo aho.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Earl McCormick",
          avatar: "//www.gravatar.com/avatar/f6965054e369471b55b4f37a369b0e04",
          company: "Healthsouth Corp",
          email: "uleunrel@oje.is",
          phone: "(269) 782-1907",
        },
      },
      isPrivate: false,
      location: "48 Culaz Boulevard",
      attendees: [],
    },
    {
      id: "729e5f40-d47f-5107-8087-dd1d945cdae7",
      calendarId: "6",
      title: "Asowa sufeh civa.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T07:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T10:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#a09e9e",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Juon mearwin leziji kohahkam tiniv jejohi ubine jumucbi ga moltin te iswubdu kascika uki mi dabhuvo nasdagubu.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Lura Flores",
          avatar: "//www.gravatar.com/avatar/b9e4403273aa4d9a151e1674b8957c60",
          company: "Temple-Inland Inc",
          email: "kulfejene@vak.an",
          phone: "(626) 977-3739",
        },
      },
      isPrivate: false,
      location: "1095 Ricver Pass",
      attendees: [
        "Elizabeth Greer",
        "Andrew Cobb",
        "Henry Paul",
        "Zachary Montgomery",
      ],
    }
  ),
  new Event({
    id: "39403f40-b3fe-5822-9a7e-a3ee1dd00540",
    calendarId: "6",
    title: "Ipuzo vehiv fa.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T08:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T09:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#a09e9e",
    dragBgColor: "#a09e9e",
    borderColor: "#a09e9e",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Dij hawocpoz huti not deujav suuzumen vuguwo reut legdocze okmige ekozibel kit.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Landon Williams",
        avatar: "//www.gravatar.com/avatar/33113f52dff284a53e962f1eae4e2492",
        company: "American Electric Power Company, Inc.",
        email: "sotaf@ukkob.kp",
        phone: "(458) 207-3150",
      },
    },
    isPrivate: false,
    location: "1836 Tufhe Path",
    attendees: ["Chris Davidson", "Terry Riley"],
  }),
  new Event(
    {
      id: "7f5b389d-2648-54b0-9d34-dc2c2447049a",
      calendarId: "6",
      title: "Jih loflavhit vacuzti.",
      body:
        "Kemsukok isugizag woabi alasickaw ero weina lepevade etodup jor rencagow.",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T16:30:00.000Z",
      end: "2020-02-04T19:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#a09e9e",
      dragBgColor: "#a09e9e",
      borderColor: "#a09e9e",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Lu budic cimal wekuh rigfajmew jeg suluw dad lu aju iduleb zu re tuzfi.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Isabel Carter",
          avatar: "//www.gravatar.com/avatar/1cf95e25e60dc4a0d7cd259f30d05534",
          company: "Veritas Software Corporation",
          email: "sac@kuctek.eu",
          phone: "(669) 247-1247",
        },
      },
      isPrivate: false,
      location: "252 Pote Road",
      attendees: [],
    },
    {
      id: "04016f2d-6d36-585e-af99-aa7884a90c72",
      calendarId: "6",
      title: "Vave guc oluuk.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T22:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T00:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#a09e9e",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 105,
      comingDuration: 114,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Re zef loh tag bug fahfu nenmu jideif neflo viutaim dolak gaulsi zupa bigwerek hu.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Mike Terry",
          avatar: "//www.gravatar.com/avatar/6309cabd91d7b24588cff1a17787c7fb",
          company: "Puget Energy Inc.",
          email: "lap@te.mh",
          phone: "(722) 262-8443",
        },
      },
      isPrivate: false,
      location: "524 Modal Terrace",
      attendees: [],
    },
    {
      id: "833d9517-326a-5274-a2d7-cd70fa855c6d",
      calendarId: "6",
      title: "Coztowew pejaviom mit.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T12:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T13:30:00.000Z",
      category: "task",
      dueDateClass: "morning",
      color: "#ffffff",
      bgColor: "#a09e9e",
      dragBgColor: "#a09e9e",
      borderColor: "#a09e9e",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Bufzahdo vengodcur evadiwjib dekcirbuz bacasem esi sumujrep puvwad eloofime elili fuel di topi ta adhodlaw zin bouwi kechopoz.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Caleb Webster",
          avatar: "//www.gravatar.com/avatar/35bf2770ab265136ee7dab9e43b7c036",
          company: "J.P. Morgan Chase & Co.",
          email: "ce@ivemudgep.gr",
          phone: "(782) 945-2542",
        },
      },
      isPrivate: false,
      location: "1231 Nockib Mill",
      attendees: [
        "Ophelia Sanchez",
        "Alma Floyd",
        "Zachary Gardner",
        "Nina Salazar",
        "Cynthia Hansen",
      ],
    }
  ),
  new Event({
    id: "ae39fef1-a973-5bff-9915-c36edf54d884",
    calendarId: "6",
    title: "Uful nap wojlituc.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T02:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T05:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#a09e9e",
    dragBgColor: "#a09e9e",
    borderColor: "#a09e9e",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 49,
    comingDuration: 49,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Dezeome teluro lur sa utme kefac mevra fohu potitoef lihrij oraofte nodoac fozus ibo dob feflizpiw zi zu.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Howard Young",
        avatar: "//www.gravatar.com/avatar/a39743ab140659c17c6c2b16a5442400",
        company: "Sanmina-SCI Inc",
        email: "areaj@juk.gu",
        phone: "(930) 504-3917",
      },
    },
    isPrivate: false,
    location: "1749 Depa Court",
    attendees: ["Myrtle Roy", "Iva Thomas", "Raymond Conner"],
  }),
  new Event({
    id: "fcf2b858-f859-55f1-b058-14981b510f05",
    calendarId: "6",
    title: "Magowu vetuz fu.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T16:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T18:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#a09e9e",
    dragBgColor: "#a09e9e",
    borderColor: "#a09e9e",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Goh ti hil emruvguw kissucgi newwubeko rukijol mulinnag abnededi su geinala gahuh hambovo zon lac.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Esther Burke",
        avatar: "//www.gravatar.com/avatar/2b894af9c13e25681c2e0338cabd08c9",
        company: "Federal Mogul Corp.",
        email: "ut@rinziulo.ni",
        phone: "(961) 416-2837",
      },
    },
    isPrivate: false,
    location: "42 Pecov Terrace",
    attendees: [
      "Hattie Gibbs",
      "Mae Yates",
      "Madge Richardson",
      "Frances Peters",
      "Jimmy Willis",
      "Micheal Barrett",
      "Elnora Turner",
      "Dollie Garza",
      "Effie Morgan",
    ],
  }),
  new Event(
    {
      id: "00aa9a76-42dd-5bdc-a575-b0e5a95902a0",
      calendarId: "6",
      title: "Zo iwijow wuagosik.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T06:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T09:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#a09e9e",
      dragBgColor: "#a09e9e",
      borderColor: "#a09e9e",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Hal boc ik cufe gavunre jucto ojagda dupla efa fe ivo oh pukiskor jebesirok.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Derrick Rowe",
          avatar: "//www.gravatar.com/avatar/74bbd8e7784a8629386a187ee638a31c",
          company: "Gannett Co., Inc.",
          email: "kuh@nidpe.cu",
          phone: "(420) 729-6748",
        },
      },
      isPrivate: false,
      location: "226 Sedno Circle",
      attendees: [],
    },
    {
      id: "49b4177f-0888-5761-9c22-b4d0a9cd3fb4",
      calendarId: "6",
      title: "Vafes zalebva wehcugrir.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T08:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T12:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#a09e9e",
      dragBgColor: "#a09e9e",
      borderColor: "#a09e9e",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Noalari cegcabte ruvuhgid jozo cennejga bulel lab zubiuw lop mimac na cabze esunu toipe numnonno fazsihud hatseb kih.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Jacob Marshall",
          avatar: "//www.gravatar.com/avatar/c48776b2513508c3270c332bd103538a",
          company: "Lucent Technologies Inc.",
          email: "wafno@li.mo",
          phone: "(477) 664-2131",
        },
      },
      isPrivate: false,
      location: "1615 Zugsar Boulevard",
      attendees: [
        "Nicholas Andrews",
        "Mathilda Fowler",
        "Jeffery Delgado",
        "Melvin Yates",
        "Jennie Watson",
        "Bertha Lambert",
        "Jordan Tran",
        "Eula Allen",
        "Elizabeth Castillo",
        "Kenneth Norris",
      ],
    }
  ),
  new Event({
    id: "2d08a562-d88b-54ae-9ff0-e2e54976a0fd",
    calendarId: "6",
    title: "Ijkuz dipanaw vuron.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T18:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD") + "T20:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#a09e9e",
    dragBgColor: "#a09e9e",
    borderColor: "#a09e9e",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 15,
    comingDuration: 15,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Uma laugfu unnev zelmi ki itvaf hunakoku hacsoj diltagkis vibu ip val azu pezpa sig cob guzkos.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Gerald Stone",
        avatar: "//www.gravatar.com/avatar/ebceda662f1a60c4eb613d608b052841",
        company: "Paychex Inc",
        email: "oma@garu.tc",
        phone: "(614) 286-4254",
      },
    },
    isPrivate: false,
    location: "1469 Gitafi View",
    attendees: [
      "David Roberson",
      "Ida Kim",
      "Garrett Larson",
      "Francis Vega",
      "Mildred Carr",
      "Jerry Ramos",
    ],
  }),
  new Event({
    id: "03baf6d5-9c72-5e63-bd45-04b8508844e3",
    calendarId: "7",
    title: "Negiwi fijav majva.",
    body: "Loco tokgod ta ar ve gimidi idobaf zagu mapnek si.",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T07:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T10:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#ffd369",
    dragBgColor: "#ffd369",
    borderColor: "#ffd369",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 11,
    comingDuration: 11,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Tez muc aw mavo juitbin zojopi ut azusiku ezwin buvisoh geok taefewa juf tajmos dolro zobmid gajile.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Warren Lyons",
        avatar: "//www.gravatar.com/avatar/920c7404647a4efc84ee3f8354973178",
        company: "Scientific-Atlanta Inc",
        email: "lop@betgesko.ky",
        phone: "(287) 867-5622",
      },
    },
    isPrivate: false,
    location: "1659 Cuslib Extension",
    attendees: ["Leila Singleton", "Eugene Johnston", "Roy Day"],
  }),
  new Event({
    id: "80588947-793e-5cec-bf33-66b24de9e384",
    calendarId: "7",
    title: "Uwi ome jat.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T15:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T16:00:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#ffd369",
    dragBgColor: "#ffd369",
    borderColor: "#ffd369",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Zo uki mozkonuk od bozsol aponuom bu jag ici dej gegussi tewwicit gizof zelhiji ej.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Nell Brooks",
        avatar: "//www.gravatar.com/avatar/94ebe29cb5ce5509f1da4fe6c516ba7a",
        company: "Viad Corp",
        email: "fawogafem@hes.ro",
        phone: "(967) 316-5282",
      },
    },
    isPrivate: false,
    location: "1908 Zicciw Heights",
    attendees: [
      "Jayden Guzman",
      "Harry Sandoval",
      "Ophelia Pittman",
      "Adam Wilkerson",
      "Mable Perry",
      "Birdie Gray",
      "Edward Harrington",
      "Samuel Swanson",
      "Melvin Erickson",
      "Vernon Torres",
    ],
  }),
  new Event(
    {
      id: "33020705-32fe-59ff-af94-fa308ec573bb",
      calendarId: "7",
      title: "Hof wupoes setpoka.",
      body: "Pusrowto uhuzo keb iriigiriz sek nile zuku wable degwake aragaz.",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T14:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T15:30:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#ffd369",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Dadjo agtudef ahsemgin curvi nu vik iratak wo nevibje je izwat uktenal unu gulaslel sopna.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Lewis McCoy",
          avatar: "//www.gravatar.com/avatar/a4588b6294a5f8337de69c384bda2ba2",
          company: "Harman International Industries Inc.",
          email: "de@henatfo.my",
          phone: "(760) 771-2829",
        },
      },
      isPrivate: false,
      location: "1116 Zesov Glen",
      attendees: [],
    },
    {
      id: "4bec767c-9418-5c72-b804-c15bcd81f825",
      calendarId: "7",
      title: "Wogecu baontik welif.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T06:00:00.000Z",
      end: "2020-02-02T10:00:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#ffd369",
      dragBgColor: "#ffd369",
      borderColor: "#ffd369",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Ak aroko diztu tecuvwo unfomdo mago atapabsi guklac hidgi epasac guwgam id.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Addie Benson",
          avatar: "//www.gravatar.com/avatar/3c7d128e774db2c74bb1b8f7ff99a1b7",
          company: "Siebel Systems Inc",
          email: "ca@febgovle.bg",
          phone: "(429) 211-2129",
        },
      },
      isPrivate: false,
      location: "426 Depbiv Highway",
      attendees: ["Kate Page", "Alta Hines", "Rena Cortez", "Dorothy Burns"],
    }
  ),
  new Event(
    {
      id: "923f6619-ccac-51f0-bbfb-219fcb3324f0",
      calendarId: "7",
      title: "Ephuji ida ahva.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T04:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T08:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#ffd369",
      dragBgColor: "#ffd369",
      borderColor: "#ffd369",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Kuv vekac cawu le pakozve de sitom osuipu hocma aguda dimvac ez afokas ogpidri.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Lela Carpenter",
          avatar: "//www.gravatar.com/avatar/22547ca3ac43ed0bd71737aeedb3f170",
          company: "Lear Corporation",
          email: "dizzij@alitu.lb",
          phone: "(539) 559-2857",
        },
      },
      isPrivate: false,
      location: "537 Nuzbep Grove",
      attendees: [],
    },
    {
      id: "d167aa88-2809-551d-9fae-d5be62c68700",
      calendarId: "7",
      title: "Malnir ivhow mo.",
      body: "Norken bimin dato aprala jokovof fib wo gac dut wi.",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T13:30:00.000Z",
      end: "2020-02-04T14:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#ffd369",
      dragBgColor: "#ffd369",
      borderColor: "#ffd369",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Sawjeeg fir kuv ko linci uri fiteev bal ji mi kapupa vocofar atuza eljo.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Virginia Thompson",
          avatar: "//www.gravatar.com/avatar/7a2486c280c281adf3bfdfbc034082d7",
          company: "Airborne, Inc.",
          email: "wajfiufu@membazez.ga",
          phone: "(447) 717-9682",
        },
      },
      isPrivate: false,
      location: "479 Usno Point",
      attendees: [
        "Pearl Christensen",
        "Elsie Ball",
        "Birdie Hill",
        "Louis Watkins",
        "Bettie Lewis",
      ],
    }
  ),
  new Event(
    {
      id: "85c7d520-ea51-5188-b082-b6dddf0845ba",
      calendarId: "7",
      title: "Rocfiz agtuum jiwetjur.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T08:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T12:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#ffd369",
      dragBgColor: "#ffd369",
      borderColor: "#ffd369",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "repeated events",
      state: "Busy",
      raw: {
        memo:
          "Pomu zovog naun ceiglok azubokhaf vi ihoeha cote jikwenhev iwomo banhavfak reugfi rabbimcuk.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Tony Wells",
          avatar: "//www.gravatar.com/avatar/2533026fd2f2c094da9d142255148a21",
          company: "Navistar International Corporation",
          email: "ge@lobsohum.mn",
          phone: "(819) 626-1418",
        },
      },
      isPrivate: false,
      location: "727 Nivfe Grove",
      attendees: [],
    },
    {
      id: "827eb78b-5db3-52cd-a2f1-25508ecb82d8",
      calendarId: "7",
      title: "Okezolot sifemi gin.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T14:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T18:30:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#ffd369",
      dragBgColor: "#ffd369",
      borderColor: "#ffd369",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 39,
      comingDuration: 39,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Gihucpir ciimmo paw ew pu ikiace ifa ruzsire gaike ivu hegnu ziboh.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Kathryn Gross",
          avatar: "//www.gravatar.com/avatar/4e189d6c444700f42f9f119cb10c2c05",
          company: "ITT Industries Inc.",
          email: "nihut@za.pl",
          phone: "(843) 305-6905",
        },
      },
      isPrivate: true,
      location: "1296 Befmub Highway",
      attendees: [],
    },
    {
      id: "cc8bd14f-747c-5e00-ab24-4425d016b98b",
      calendarId: "7",
      title: "Fuseob mososmo zebrukow.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T10:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T13:00:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#ffd369",
      dragBgColor: "#ffd369",
      borderColor: "#ffd369",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "repeated events",
      state: "Busy",
      raw: {
        memo:
          "Fok kowap ne lud bofo gak micono suhemo lokdohik lalseni cirbu owedal zetoz tihjo anihauz dufnuvo.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Mabelle Bowen",
          avatar: "//www.gravatar.com/avatar/a64ccab22e11fe47def4d894043dbebd",
          company: "PolyOne Corp",
          email: "imovezzom@kodoti.tk",
          phone: "(415) 578-7451",
        },
      },
      isPrivate: true,
      location: "1864 Sagag Center",
      attendees: [],
    },
    {
      id: "ea0cad69-746c-581b-83df-e6e0e8b93921",
      calendarId: "7",
      title: "Zeveku ba zoewu.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T13:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T15:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#ffd369",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Ti givlobo fes ubi su etiwi hobuwwa vi hacor dule vi peki az kuehope vatbeb vofuk.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Patrick Lawson",
          avatar: "//www.gravatar.com/avatar/c8bb01308772a50242f7c30c9054b81a",
          company: "Echostar Communications Corporation",
          email: "lawsogu@zud.sz",
          phone: "(866) 901-8374",
        },
      },
      isPrivate: false,
      location: "982 Tabva Center",
      attendees: [],
    },
    {
      id: "76cd6380-b54f-5dcf-bb74-205ef4b33407",
      calendarId: "8",
      title: "Havewfu bogiw uhecolfi.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T12:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") + "T16:30:00.000Z",
      category: "task",
      dueDateClass: "morning",
      color: "#ffffff",
      bgColor: "#fd6256",
      dragBgColor: "#fd6256",
      borderColor: "#fd6256",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Owbentet tes kosuzu zevnoji pezsuhle moihuce hure nerlaroz pikjegme legirgir wiavi abiedsuf.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Lottie Weaver",
          avatar: "//www.gravatar.com/avatar/4cfd45f1a00e0f8c32219369e161ee12",
          company: "Pinnacle West Capital Corp",
          email: "nut@me.ci",
          phone: "(855) 792-2637",
        },
      },
      isPrivate: false,
      location: "1708 Worhu Turnpike",
      attendees: ["Winifred Hansen", "Isaac Santos", "Cory Huff"],
    }
  ),
  new Event(
    {
      id: "d07080e8-234c-5b62-be46-b61a9d007f17",
      calendarId: "8",
      title: "Uz fuboiga obaumviv.",
      body: "",
      isAllday: true,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T22:30:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") + "T00:30:00.000Z",
      category: "allday",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fd6256",
      dragBgColor: "#fd6256",
      borderColor: "#fd6256",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Gidlim seklon ibusam ukmuk ivewipa wuija si hiwfab owzeklip gemedgaz se kasbazu dudhew ze jinebuvi imva kic evi.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Zachary Sandoval",
          avatar: "//www.gravatar.com/avatar/5b6aeab8b545d9c14462c305a7149c15",
          company: "St. Jude Medical Inc",
          email: "abeli@octu.nl",
          phone: "(687) 529-2393",
        },
      },
      isPrivate: false,
      location: "336 Medhaw Junction",
      attendees: [],
    },
    {
      id: "69e23134-c91c-5d0b-ad2b-a7556da7cde8",
      calendarId: "8",
      title: "Su ta fepi.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") + "T22:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-3, "days")
          .format("YYYY-MM-DD") + "T00:00:00.000Z",
      category: "time",
      dueDateClass: "",
      color: "#ffffff",
      bgColor: "#fd6256",
      dragBgColor: "#fd6256",
      borderColor: "#fd6256",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 47,
      comingDuration: 47,
      recurrenceRule: "",
      state: "Free",
      raw: {
        memo:
          "Ag jeachas fujsa nepi kuhem ikizin addo zechab eppan pukihob azupzu bipe jujimej edazu fe.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Cory Brown",
          avatar: "//www.gravatar.com/avatar/283df7ad5e494741ff8e1642b13ae68a",
          company: "Qualcomm Inc",
          email: "gezreboku@zabperget.ki",
          phone: "(217) 720-3702",
        },
      },
      isPrivate: false,
      location: "1919 Ruci Turnpike",
      attendees: [
        "Jason Paul",
        "Ophelia Owen",
        "Sophia Stone",
        "Wayne Poole",
        "Verna Cohen",
        "Rhoda Cortez",
        "Alice Fuller",
        "Bernice Hart",
        "Tyler Wilkins",
        "Lloyd Holland",
      ],
    }
  ),
  new Event({
    id: "55fe5e98-53e9-58d1-a0e3-733dd9fc51f3",
    calendarId: "8",
    title: "Hac cezlobi ta.",
    body: "",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD") + "T20:30:00.000Z",
    end: "2020-02-03T23:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fd6256",
    dragBgColor: "#fd6256",
    borderColor: "#fd6256",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Free",
    raw: {
      memo: "Fur feh sefvul gig zar nen wa seg ufge wece ugpemja ebe ohde.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Lelia Weaver",
        avatar: "//www.gravatar.com/avatar/a90236ea1083b74ba924028c80e5668f",
        company: "New York Times Company",
        email: "romiluba@uveab.ms",
        phone: "(408) 268-9027",
      },
    },
    isPrivate: false,
    location: "1915 Zosez Square",
    attendees: [
      "Willie Aguilar",
      "Georgia Yates",
      "Eula Simmons",
      "Cory Swanson",
      "Russell Rowe",
      "Vincent Duncan",
      "Jack Shaw",
      "Kevin Saunders",
    ],
  }),
  new Event({
    id: "edb26187-cc3b-5ea6-a385-f8beed518744",
    calendarId: "8",
    title: "Gupgiuni lolte ri.",
    body: "Pefe honed enmuj ja tojtake nilesi cithevat zuw doosvo fir.",
    isAllday: true,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-3, "days")
        .format("YYYY-MM-DD") + "T13:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T17:30:00.000Z",
    category: "allday",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fd6256",
    dragBgColor: "#fd6256",
    borderColor: "#fd6256",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Kog hetkuir ibnuciho hegfeiso jofuc zid jufpe guh fen kefnap zonlutupi pi zil luz cumoco bone pi pam.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Lora Hill",
        avatar: "//www.gravatar.com/avatar/d89faa5a0a3a1986f9ee533f067e6255",
        company: "Ingles Market Inc",
        email: "buzid@ga.ie",
        phone: "(583) 369-2082",
      },
    },
    isPrivate: false,
    location: "340 Leeno Grove",
    attendees: ["Ian Day"],
  }),
  new Event({
    id: "ceb3fab7-4126-5ac8-9136-b1bc886fcf67",
    calendarId: "8",
    title: "Eneovi cuulu uswom.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T23:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(+2, "days")
        .format("YYYY-MM-DD") + "T03:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fd6256",
    dragBgColor: "#fd6256",
    borderColor: "#fd6256",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: true,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "repeated events",
    state: "Busy",
    raw: {
      memo:
        "Vaj duili lurge zacfemvo uhaewkeh let cuffijhov mir li sugtonkeb doj mukoag osu cilfice rosjik oh epi.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Hester Reynolds",
        avatar: "//www.gravatar.com/avatar/2da9e444ab4a1448161832a9d87a825c",
        company: "Commercial Metals Co.",
        email: "naguc@fa.cm",
        phone: "(610) 568-4273",
      },
    },
    isPrivate: false,
    location: "1587 Funzad Square",
    attendees: ["Herman Page", "Leona Alvarez"],
  }),
  new Event(
    {
      id: "6e56db7b-cee2-5694-9d6b-3aa964545b4f",
      calendarId: "8",
      title: "Afu bum ped.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T12:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-1, "days")
          .format("YYYY-MM-DD") + "T14:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#fd6256",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: false,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Sav egece loljas nowjintad agi ko jedzoz nambuk akahaleg fusvuvpew puti pe ehuwo noli.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Eugenia Robertson",
          avatar: "//www.gravatar.com/avatar/616116389b6ad1c6051e097dbfcd36e8",
          company: "Steelcase Inc",
          email: "zaw@ezwuiho.bw",
          phone: "(647) 852-6037",
        },
      },
      isPrivate: false,
      location: "1895 Ipate Trail",
      attendees: [],
    },
    {
      id: "e5953869-2237-54b9-ae93-933766f03278",
      calendarId: "8",
      title: "Pew hohoktiw henomer.",
      body: "",
      isAllday: false,
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T09:00:00.000Z",
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(+2, "days")
          .format("YYYY-MM-DD") + "T10:00:00.000Z",
      category: "milestone",
      dueDateClass: "",
      color: "#fd6256",
      bgColor: "transparent",
      dragBgColor: "transparent",
      borderColor: "transparent",
      customStyle: "",
      isFocused: false,
      isPending: false,
      isVisible: true,
      isReadOnly: true,
      goingDuration: 0,
      comingDuration: 0,
      recurrenceRule: "",
      state: "Busy",
      raw: {
        memo:
          "Jupcufoh vohwazoh akiwabe fiw hahufov uwogifig affeldil jabkuvrig gefe mipaj masdiwu ji dasug.",
        hasToOrCc: false,
        hasRecurrenceRule: false,
        location: null,
        class: "public",
        creator: {
          name: "Albert May",
          avatar: "//www.gravatar.com/avatar/a0b35ec845f7b3cb769b1369046fb36e",
          company: "Airgas, Inc.",
          email: "imdajhi@of.at",
          phone: "(862) 736-4252",
        },
      },
      isPrivate: false,
      location: "389 Viwo Trail",
      attendees: ["Ethan Hudson"],
    }
  ),
  new Event({
    id: "a4ace7c3-92c2-5b40-b447-948ec778283c",
    calendarId: "8",
    title: "Wojuz ne netju.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T07:00:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD") + "T11:00:00.000Z",
    category: "task",
    dueDateClass: "morning",
    color: "#ffffff",
    bgColor: "#fd6256",
    dragBgColor: "#fd6256",
    borderColor: "#fd6256",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 0,
    comingDuration: 0,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Agerurap nojetoj du gu edetko rohlemwe nijet zusi copcahte sisnavjes vej lasupo cobwa atgabfi.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Julian Christensen",
        avatar: "//www.gravatar.com/avatar/3f0fe06c9a248631aff5a36c85dec34f",
        company: "Omnicom Group",
        email: "gufu@kizvu.an",
        phone: "(603) 482-8696",
      },
    },
    isPrivate: false,
    location: "1063 Cope Key",
    attendees: [
      "Chris Clayton",
      "Johnny Barnes",
      "Nora Reese",
      "Eliza Alvarado",
      "Larry Graham",
      "Joe Morrison",
      "Dean Stone",
      "Philip Foster",
    ],
  }),
  new Event({
    id: "c875a605-c914-58e5-affe-34ab54ec274d",
    calendarId: "8",
    title: "El ezotikgo lashoutu.",
    body: "",
    isAllday: false,
    start:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T00:30:00.000Z",
    end:
      moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD") + "T04:30:00.000Z",
    category: "time",
    dueDateClass: "",
    color: "#ffffff",
    bgColor: "#fd6256",
    dragBgColor: "#fd6256",
    borderColor: "#fd6256",
    customStyle: "",
    isFocused: false,
    isPending: false,
    isVisible: true,
    isReadOnly: false,
    goingDuration: 4,
    comingDuration: 4,
    recurrenceRule: "",
    state: "Busy",
    raw: {
      memo:
        "Nidrewob po paez loalarom dac ilwesew etijosgeb na newfam ric tecail cad woducac aldula hub og.",
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: "public",
      creator: {
        name: "Kenneth James",
        avatar: "//www.gravatar.com/avatar/537e605f478a415e9a39806f5ebe3ced",
        company: "VF Corporation",
        email: "mij@efpalca.fk",
        phone: "(422) 847-7675",
      },
    },
    isPrivate: false,
    location: "1294 Arzes Grove",
    attendees: [],
  }),
];
